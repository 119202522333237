import { AffiliateBody, SearchIcon, SearchInput } from "../Affilliate/styled";
import { BoxTitleRadius } from "../Dashboard/styled";
import IconSearch from "../../assets/Affilliate/searchIcon.svg";
import { BlueTable } from "../Bonus/styled";
import Table from "../../components/Table";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { BoxTitlePage } from "../Staking/styled";
import { RootState, useDispatch, useSelector } from "../../store/reDucStore";
import { getTransitionList } from "../../store/Slices/Transition";
import { useTonAddress } from "@tonconnect/ui-react";
import Pagination from "../../components/Pagination";
import { ContextProviderWrapper } from "../../components/Context";
import dayjs from "dayjs";
import { TextStatus } from "../Withdraw/Table/styled";
import { BoxFillter, BoxTxType, FillterIcon, HistoryWapper } from "./styled";
import { formatNumber } from "../../utils/formatNumber";
import InfiniteScroll from "react-infinite-scroll-component";

const HistoryPage = () => {
  const { setTitle }: any = useContext(MenuContext);
  const address = useTonAddress();
  useEffect(() => {
    setTitle("Transaction History");
  }, []);
  const dispatch = useDispatch();
  const TransitionList = useSelector(
    (state: RootState) => state.Transition.TransitionList
  );
  const [HistoryList, setHistory] = useState<any>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");

  const { isMobile } = useContext(ContextProviderWrapper)!;

  const fetchMoreData = async () => {
    if (pageIndex > totalPage) {
      return;
    }
    setLoading(true);
    const res = await dispatch(
      getTransitionList({
        "pagination[page]": pageIndex,
        "pagination[perPage]": 30,
        "sort[field]": "created_at",
        "sort[order]": "DESC",
      })
    );
    if (res?.payload?.data?.total) {
      setTotalPage(Math.ceil(res?.payload?.data?.total / 2));
      setHistory([...HistoryList, ...res?.payload?.data?.data]);
      setPageIndex(pageIndex + 1);
    }
    setLoading(false);
  };
  console.log("total: " + totalPage);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchMoreData();
    }
  }, [localStorage.getItem("token"), address]);
  return (
    <HistoryWapper>
      <BoxTitlePage>
        <h3>History</h3>
      </BoxTitlePage>
      <AffiliateBody>
        <BoxTitleRadius>
          {isMobile ? (
            <h3 className="Text-his">History</h3>
          ) : (
            <h3 className="Text-his">Transaction History</h3>
          )}
          {/* <SearchInput style={{ background: "#2D2F35" }} className="his">
            <SearchIcon>
              <img src={IconSearch} alt="icon" />
            </SearchIcon>
            <input type="text" placeholder="Search here..." />
          </SearchInput> */}
          {/* <BoxFillter>
            <FillterIcon>
              <img src="/assets/fillter_icon.svg" />
            </FillterIcon>
            <BoxTxType>
              <p>
                {ListType.map((item: any) =>
                  item.key === key ? item.label : ""
                )}
              </p>
              <ul>
                {ListType.map((item: any) => (
                  <li key={item.key}>{item.label}</li>
                ))}
              </ul>
            </BoxTxType>
          </BoxFillter> */}
        </BoxTitleRadius>
        <InfiniteScroll
          dataLength={HistoryList.length}
          next={fetchMoreData}
          hasMore={true}
          loader={<h4></h4>}
        >
          <BlueTable>
            {/* {loading ? (
              <p style={{ color: "#fff", textAlign: "center", padding: "8px" }}>
                Looading...
              </p>
            ) : ( */}
            <Table
              data={loading ? [] : HistoryList || []}
              // data={FakeApi}
              keyObj={[
                {
                  th: "ID Transaction",
                  key: "transaction_id",
                  callback: (data: any) => {
                    if (isMobile) {
                      return (
                        <div>
                          <p>
                            {data.transaction_id?.slice(0, 4)}...
                            {data.transaction_id?.slice(-4)}
                          </p>
                          <TextStatus>
                            {data.type === "CLAIM" ? "Success" : ""}
                          </TextStatus>
                        </div>
                      );
                    } else {
                      return (
                        <p>
                          {data.transaction_id?.slice(0, 4)}...
                          {data.transaction_id?.slice(-4)}
                        </p>
                      );
                    }
                  },
                },
                {
                  th: "Datetime",
                  key: "created_at",
                  callback: (data: any) => {
                    if (isMobile) {
                      return (
                        <div>
                          <p>
                            {dayjs(data.created_at).format(
                              "DD/MM/YYYY HH:MM:ss"
                            )}
                          </p>
                        </div>
                      );
                    } else {
                      return (
                        <p>{dayjs(data.created_at).format("DD/MM/YYYY ")}</p>
                      );
                    }
                  },
                },
                {
                  th: "Amount",
                  key: "token_value",
                  callback: (data: any) => {
                    if (isMobile) {
                      return (
                        <div>
                          <p>Amount</p>
                          <p>
                            {formatNumber(data.token_value?.toFixed(2))}{" "}
                            <img
                              src="./assets/ton_symbol.png"
                              style={{ maxHeight: "15px" }}
                            />{" "}
                            <span>
                              (~$ {formatNumber(data?.amount?.toFixed(2))})
                            </span>
                            <span>
                              ({data.iton_value
                                ? `+ ${formatNumber(
                                    data.iton_value?.toFixed(2)
                                  )}`
                                : ""}
                              {data.iton_value ? (
                                <img
                                  src="./assets/iton_symbol.svg"
                                  style={{ maxHeight: "15px" }}
                                />
                              ) : (
                                ""
                              )})
                            </span>
                          </p>
                        </div>
                      );
                    } else {
                      return (
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          $ {formatNumber(data?.amount?.toFixed(2))}
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                          >
                            (~ {formatNumber(data.token_value?.toFixed(2))}{" "}
                            <img
                              src="./assets/ton_symbol.png"
                              style={{ maxHeight: "15px" }}
                            />
                            {data.iton_value
                              ? `+ ${formatNumber(data.iton_value?.toFixed(2))}`
                              : ""}
                            {data.iton_value ? (
                              <img
                                src="./assets/iton_symbol.svg"
                                style={{ maxHeight: "15px" }}
                              />
                            ) : (
                              ""
                            )}
                            )
                          </span>{" "}
                        </p>
                      );
                    }
                  },
                },
                {
                  th: "Type Transaction",
                  key: "type",
                  callback: (data: any) => {
                    switch (data.type) {
                      case "PROFIT_DAILY":
                        return "Profit Daily";
                        break;
                      case "BRANCH_BONUS":
                        return "Branch Bonus";
                        break;
                      case "AFFILIATES_BONUS":
                        return "Affiliate";
                        break;
                      case "INVESTMENT":
                        return "Investment";
                        break;
                      case "RANKING_BONUS":
                        return "Rankings Bonus";
                        break;
                      case "CLAIM":
                        return "Withdraw";
                        break;
                      case "INTERNAL_INVESTMENT":
                        return "Internal Investment";
                        break;
                      case "TRANSFER":
                        return "Transfer";
                        break;
                      case "WITHDRAWAL":
                        return "Withdraw";
                        break;
                      case "RANKING_BONUS_DAILY":
                        return "Daily Ranking Bonus";
                        break;
                      case "INVESTMENT_BONUS":
                        return "Bonus Investment";
                        break;
                      case "BUY_TOKEN":
                        return "Buy iTON";
                        break;
                      case "FARMING":
                        return "Farming";
                        break;
                      case "STAKING_ITON":
                        return "Staking";
                        break;
                      case "FARMING_AFFILIATES_BONUS":
                        return "Farming Affiliate";
                        break;
                      case "FARMING_BRANCH_BONUS":
                        return "Farming Branch Bonus";
                        break;
                      case "FARMING_PROFIT_DAILY":
                        return "Farming Daily Profit";
                        break;
                      case "FARMING_ITON_REWARD":
                        return "Farming Bonus iTON";
                        break;
                      default:
                        return isMobile ? (
                          <p>{data.type}</p>
                        ) : (
                          <p>{data.type}</p>
                        );
                    }
                  },
                },
                {
                  th: "Tx id",
                  key: "tx_id",
                  callback: (data: any) => {
                    if (isMobile) {
                      return (
                        <div>
                          <p>
                            <span>Tnxhash:</span>{" "}
                            <span>
                              {data.tx_id?.slice(0, 4)}...
                              {data.tx_id?.slice(-4)}
                            </span>
                          </p>
                        </div>
                      );
                    } else {
                      return data.tx_id ? (
                        <a
                          href={`https://tonviewer.com/transaction/${data.tx_id}`}
                          target="_blank"
                        >
                          {data.tx_id?.slice(0, 4)}...{data.tx_id?.slice(-4)}
                        </a>
                      ) : (
                        "--"
                      );
                    }
                  },
                },
                {
                  th: "Description",
                  key: "remark",
                  callback: (data: any) => {
                    if (isMobile) {
                      return (
                        <div>
                          <p>
                            <span>Description:</span>{" "}
                            <span style={{maxWidth:"50%"}} >{roundNumbersInString(data.remark, 2)}</span>
                          </p>
                        </div>
                      );
                    } else {
                      return <p>{roundNumbersInString(data.remark, 4)}</p>;
                    }
                  },
                },
              ]}
            />
            {/* )} */}

            {localStorage.getItem("token") && address && (
              <Pagination
                items={TransitionList?.total}
                itemsPerPage={15}
                fetchApi={async (page: any) => {
                  setLoading(true);
                  const res = await dispatch(
                    getTransitionList({
                      ...page,
                      "sort[field]": "created_at",
                      "sort[order]": "DESC",
                    })
                  );
                  if (res?.payload?.data?.total) {
                    setHistory(res?.payload?.data?.data);
                  }
                  setLoading(false);
                }}
              />
            )}
          </BlueTable>
        </InfiniteScroll>
      </AffiliateBody>
    </HistoryWapper>
  );
};
export default HistoryPage;

const ListType = [
  {
    key: "",
    label: "All",
  },
  {
    key: "PROFIT_DAILY",
    label: "Profit Daily",
  },
  {
    key: "BRANCH_BONUS",
    label: "Branch Bonus",
  },
  {
    key: "AFFILIATES_BONUS",
    label: "Affiliates Bonus",
  },
  {
    key: "INVESTMENT",
    label: "Investment",
  },
  {
    key: "RANKING_BONUS",
    label: "Rankings Bonus",
  },
  {
    key: "CLAIM",
    label: "Withdraw",
  },
  {
    key: "INTERNAL_INVESTMENT",
    label: "Investment",
  },
  {
    key: "TRANSFER",
    label: "Transfer",
  },
];

function roundNumbersInString(str: any, decimalPlaces = 4) {
  return str.replace(/(\d+(\.\d+)?)/g, (match: any) => {
    return `~${parseFloat(match).toFixed(decimalPlaces)}`;
  });
}
