import styled from "styled-components";
import BgPanner from "../../assets/Withdraw/BalanceBg.png";
import { BoxNotification, Submit } from "../Staking/styled";
import { BoxTitleRadius } from "../Dashboard/styled";
import { PannerNetword } from "../Network/style";
import { TableContainer } from "../../components/Table";

export const WithdrawWapper = styled.div`
  width: 100%;
  padding: 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1660px;
  margin: 0px auto;
  @media screen and (max-width: 1040px) {
    padding: 40px 24px 80px;
  }
  @media only screen and (max-width: 820px) {
    .withdraw_colum {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px 15px 100px;
    gap: 0;
    ${PannerNetword} {
      margin-bottom: 15px;
    }
    ${TableContainer} {
      background-color: transparent;
      table {
        min-width: auto;
      }
      thead {
        display: none;
      }
      tbody {
        display: block;
        tr {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 10px;
          background: #121315;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          td {
            padding: 0;
            &:nth-child(1) {
              display: none;
            }
            &:nth-child(2) {
              width: 100%;
              order: 3;
              margin: 10px 0;
            }
            &:nth-child(3),
            &:nth-child(4) {
              display: flex;
              align-items: center;
              width: calc(50% - 3px);
              order: 3;
              background-color: red;
              border-radius: 8px;
              background: #2d2f35;
              padding: 8px;
              min-height: 60px;
              & > div {
                & > p {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  &:nth-child(1) {
                    margin-bottom: 7px;
                    color: #878ea0;
                    font-size: 16px;
                    text-transform: capitalize;
                  }
                  &:nth-child(2) {
                    font-size: 16px;
                    color: #dce3eb;
                  }
                }
              }
            }
            &:nth-child(4) {
              margin-left: auto;
            }
            &:nth-child(5) {
              width: 50%;
              order: 2;
              & > div {
                & > p {
                  text-align: right;
                  color: #aad6ff;
                  font-size: 14px;
                  text-transform: capitalize;
                }
              }
            }
            &:nth-child(6) {
              order: 1;
              width: 50%;
            }
          }
        }
      }
    }
  }
`;
export const WithdrawConten = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const WithdrawInputGroup = styled.div`
  width: calc(42% - 16px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  @media screen and (max-width: 767px) {
    width: 100%;
    gap: 15px;
  }
`;

export const BoxWallet = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const WithdrawBalance = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  background-image: url(${BgPanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  padding: 16px;
  border-radius: 16px;
  width: calc((100% - 48px) / 4);
  align-self: stretch;
  img {
    align-self: stretch;
  }
  @media screen and (max-width: 1280px) {
    width: calc((100% - 16px) / 2);
  }
  @media screen and (max-width: 767px) {
    & > img {
      width: 98px;
      height: 98px;
      align-self: auto;
    }
    & > div {
      width: 100%;
    }
  }
  @media screen and (max-width: 820px) {
    width: 100%;
  }
`;
export const WithdrawBalanceText = styled.div`
  h6 {
    color: #0085ff;
    font-family: "AnekLatin-Regular";
    font-size: 21px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
  }
  h3 {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-family: "PlusJakartaSans";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.54px;
    img {
      max-width: 24px;
      margin-right: 5px;
    }
    .ant-dropdown-trigger,
    .ant-space-item {
      display: flex;
      align-items: center;
    }
  }
  p {
    color: #afb4c0;
    font-family: "AnekLatin-Regular";
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
  }

  .Text_price {
    color: #afb4c0;
    font-family: "AnekLatin-Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 5px;
    display: flex;
  }
  @media screen and (max-width: 767px) {
    h3 {
      margin-bottom: 5px;
    }
  }
`;
export const WithdrawInputBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  @media screen and (max-width: 767px) {
    border-radius: 16px;
    background: #1b1c20;
    padding: 22px 0 0;
    ${BoxTitleRadius} {
      h3 {
        margin-bottom: 0;
      }
    }
  }
`;
export const WithdrawTabs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  .Tab_Active {
    ${BoxTitleRadius} {
      h3 {
        width: 100%;
        text-align: left;
        background: linear-gradient(
          90deg,
          #ffffff 51.48%,
          #2d2f35 171.59%
        ) !important;
        background-clip: text !important;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
      }
    }
  }
`;
export const WithdrawTab = styled.div<{ show?: any }>`
  cursor: pointer;
  width: 100%;
  position: relative;
  overflow: ${({ show }) => (show ? "initial" : "hidden")};
  border-radius: 34px;
  background-color: #5a5f6b;
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: ${({ show }) => (show ? -1 : 1)};
    opacity: 0;
    cursor: pointer;
  }
  ${BoxTitleRadius} {
    h3 {
      width: 100%;
      text-align: left;
      background: linear-gradient(
        90deg,
        #ffffff50 51.48%,
        #2d2f35 171.59%
      ) !important;
      background-clip: text !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 10px;
    padding: 5px 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    opacity: ${({ show }) => (show ? 1 : 0)};
    pointer-events: ${({ show }) => (show ? "auto" : "none")};
    transition: all 0.2s linear;
    background: #1b1c20;
    box-shadow: 0px 4px 4px 0px #ffffff50;
    border: 1px solid #ffffff50;
    z-index: 10;
    li {
      font-size: 21px;
      line-height: 100%;
      font-family: "AnekLatin-Regular";
      font-weight: 500;
      a {
        color: #fff;
        padding: 5px 15px;
      }
      &::hover {
        background: #ffffff50;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: calc(100% - 20px);
    margin: 0px auto;
  }
`;
export const WithdrawInput = styled.div`
  border-radius: 16px;
  background: #1b1c20;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  @media screen and (max-width: 767px) {
    padding-left: 12px;
    padding-right: 12px;
    ${Submit} {
      margin-top: 15px;
    }
  }
`;

export const WithdrawInputAmount = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h3 {
    color: var(--Gray-Base, #878ea0);
    font-family: "AnekLatin-Regular";
    font-size: 17px;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 5px;
    display: flex;
    justify-content: space-between;
    img {
      max-width: 20px;
    }
  }
  div {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background: var(--Gray-90, #2d2f35);
    width: 100%;
    margin-bottom: 12px;
    height: 56px;
    button {
      color: var(--Blue-Base, #0085ff);
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: "AnekLatin-Regular";
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
      background-color: transparent;
      border: none;
      padding: 0px;
      margin: 0px;
      padding: 16px;
    }
    input {
      color: var(--Blue-10, #cce7ff);
      leading-trim: both;
      text-edge: cap;
      font-family: "AnekLatin-Regular";
      font-size: 25px;
      font-weight: 400;
      line-height: 100%; /* 133.333% */
      background-color: transparent;
      border: none;
      outline: none;
      width: calc(100% - 80px);
      padding-left: 16px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  p {
    color: var(--Gray-Base, #878ea0);
    leading-trim: both;
    text-edge: cap;
    font-family: "PlusJakartaSans";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    text-align: right;
    margin-bottom: 16px;
  }
  ${BoxNotification} {
    div {
      aspect-ratio: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: fit-content;
      gap: 12px;
      margin-bottom: 0px;
      padding: 0px !important;
    }
    p {
      color: var(--Gray-40, #80c2ff);
      font-family: AnekLatin-Regular;
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0.15px;
      width: fit-content;
      margin: 0px;
    }
    h6 {
      color: var(--Gray-Base, #c3c6cf);
      font-family: AnekLatin-Regular;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
      padding-right: 12px;
      display: flex;
    }
    span {
      color: var(--Blue-Base, #fff);
    }
  }
  @media screen and (max-width: 767px) {
    border-radius: 8px;
    background: #2d2f35;
    padding: 16px;
    div {
      border-radius: 8px;
      background: #1b1c20;
      justify-content: unset;
      width: auto;
      margin-bottom: 0;
      button {
        margin-left: auto;
      }
    }
    p {
      width: max-content;
      margin: 12px 0 12px auto;
    }
  }
`;
export const BoxSelectWallet = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  background: transparent !important;
  width: fit-content !important;
  height: fit-content !important;
  cursor: pointer;
  p {
    color: var(--Gray-Base, #878ea0);
    font-family: "AnekLatin-Regular";
    font-size: 17px;
    font-weight: 400;
    line-height: 100%;
    white-space: nowrap;
    margin: 0px;
    padding: 0px 5px;
  }
  div {
    display: flex;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    gap: 5px;
    position: relative;
    background-color: #fff;
    white-space: nowrap;
    margin: 0px;
    width: fit-content !important;
    height: fit-content !important;
    p {
      color: var(--Blue-70, #0059aa);
      font-family: "AnekLatin-Regular";
      font-size: 17px;
      font-weight: 400;
      line-height: 100%;
      margin: 0px;
    }
    ul {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(calc(100% + 3px));
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 5px;
      border-radius: 5px;
      background-color: #fff;
      width: 100%;
      transition: all 0.3s linear;
      li {
        color: var(--Blue-70, rgb(0, 0, 0));
        font-family: "AnekLatin-Regular";
        font-size: 17px;
        font-weight: 400;
        line-height: 100%;
        cursor: pointer;
      }
    }
  }
`;
export const BoxDeposit = styled.div``;
export const WithdrawHisBox = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
`;
export const TableWithdrawMobile = styled.div`
  padding: 12px;
  border-radius: 16px;
  background: #1b1c20;
  ${BoxTitleRadius} {
    padding-left: 5px;
  }
`;
