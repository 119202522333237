import { useEffect } from "react";

export const useServiceWorker = () => {
    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.register("/service-worker.js", { scope: "/" })
                .then(reg => {
                    console.log("✅ Service Worker registered!");

                    navigator.serviceWorker.addEventListener("message", (event) => {
                        console.log("📩 Nhận từ Service Worker:", event.data);
                    });

                    setTimeout(() => {
                        if (reg.active) {
                            reg.active.postMessage({ type: "checkCache" });
                        }
                    }, 2000);
                })
                .catch(error => console.error("❌ Service Worker registration failed:", error));
            if (navigator.serviceWorker.controller) {
                console.log(
                    `This page is currently controlled by: ${navigator.serviceWorker.controller}`,
                );
            } else {
                console.log("This page is not currently controlled by a service worker.");
            }
        }
    }, []);
};

export const sendMessageToSW = (message: any) => {
    console.log("📤 Gửi tới Service Worker:", message);
    
    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(message);
    } else {
        console.log("❌ Không tìm thấy Service Worker");
    }
};