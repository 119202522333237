import { Submit } from "../Staking/styled";
import { BoxTitleRadius } from "../Dashboard/styled";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { RootState, useDispatch, useSelector } from "../../store/reDucStore";
import { getTokenInfo } from "../../store/Slices/Token";
import toast from "react-hot-toast";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import NewBg from "../../assets/Withdraw/wdrawBg.png";
import wdrawIcon from "../../assets/Withdraw/WithdrawIcon.png";
import {
  TranferITONSubmit,
  TranferSubmit,
  WidthrawSubmit,
  getBalance,
  getCONVERTList,
  getDEPOSITList,
  getFee,
  getTRANSFERList,
  getValueWidthrawByDay,
  getWidthrawList,
} from "../../store/Slices/Withdraw";
import { getDasboardInfo } from "../../store/Slices/Dashboard";
import styled from "styled-components";
import { PannerNetword } from "../Network/style";
import {
  BoxWallet,
  TableWithdrawMobile,
  WithdrawBalance,
  WithdrawBalanceText,
  WithdrawConten,
  WithdrawHisBox,
  WithdrawInputBox,
  WithdrawInputGroup,
  WithdrawTab,
  WithdrawTabs,
  WithdrawWapper,
} from "./style";
import TableWithdraw from "./Table";
import { ContextProviderWrapper } from "../../components/Context";
import { formatNumber } from "../../utils/formatNumber";
import WidthRawBox from "./Elm/withdrawBox";
import TransferBox from "./Elm/TransferBox";
import ModalConfirm from "./modalConfirm";
import { Dropdown, Space } from "antd";
import { fromNano, toNano } from "@ton/core";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { iTonOnChain, iTonReward, ton_internal, TonOnChain } from "./const";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import DepositBox from "./Elm/depositBox";
import ConverBox from "./Elm/convertBox";
import { instance } from "../../store/instance";

const Withdraw = () => {
  const [tonConnectUI] = useTonConnectUI();
  const [amount, setAmount] = useState(0);
  const { id } = useParams<{ id: string }>();
  const [revierAddress, setRevierAddress] = useState("");
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [loadingSub, setLoadingSub] = useState(false);
  const [iTonPrice, setITonPrice] = useState<any>(0);
  const { setTitle, setDes, handleOpenCustom, isOpen }: any =
    useContext(MenuContext);

  const [maxByDay, setMaxByDay] = useState(0);
  const [tabsActive, setTabsActive] = useState(1);
  const address = useTonAddress();

  const handleGetItonPrice = async () => {
    let price = await axios.get(
      "https://dev-tonholding.netlify.app/api/price_iton"
    );
    setITonPrice(price.data.data);
  };
  useEffect(() => {
    handleGetItonPrice();
  }, []);
  useEffect(() => {
    setTitle("Wallet");
    setDes("Easy to withdraw from your wallet");
  }, []);

  const hadleGetValByDay = async () => {
    const res = await dispatch(getValueWidthrawByDay());
    console.log("resssssssss");
    if (res?.payload?.data?.amount_ton) {
      setMaxByDay(res?.payload?.data?.amount_ton);
    }
  };
  useEffect(() => {
    if (!isOpen) {
      setLoadingSub(false);
    }
  }, [isOpen]);
  const dispatch = useDispatch();

  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const WidthrawList = useSelector(
    (state: RootState) => state.withdraw.WidthrawList
  );
  const balanceWithdraw = useSelector(
    (state: RootState) => state.withdraw.balanceWithdraw
  );
  const { DEPOSITList, CONVERTList, TRANSFERList } = useSelector(
    (state: RootState) => state.withdraw
  );
  const Fee = useSelector((state: RootState) => state.withdraw.settingList);
  const [fee, setFee] = useState(0);

  useEffect(() => {
    if (
      Fee?.data?.find((item: any) => item.name === "Fee.WithDraw.Token.Percent")
        ?.value
    ) {
      setFee(
        Number(
          Fee?.data?.find(
            (item: any) => item.name === "Fee.WithDraw.Token.Percent"
          )?.value
        )
      );
    }
  }, [
    Fee?.data?.find((item: any) => item.name === "Fee.WithDraw.Token.Percent")
      ?.value,
  ]);
  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      dispatch(getDasboardInfo());
      dispatch(getTokenInfo());
      dispatch(getWidthrawList());
      dispatch(getBalance());
      dispatch(getFee());
      dispatch(getDEPOSITList());
      dispatch(getCONVERTList());
      dispatch(getTRANSFERList());
    }
  }, [localStorage.getItem("token"), address]);

  const handleWithdraw = async (wallet: any) => {
    // closed WidthRaw
    // if(tabsActive === 1){
    //   return;
    // }
    console.log("wallet?.key" , wallet?.key);
    
    if (id !== "transfer" && Number(amount) > balanceWithdraw?.money_token) {
      toast.error("Balance Not Enough");
      return;
    }
    if (
      id === "transfer" &&
      wallet?.key === "TON_Mainnet" &&
      Number(amount) > balanceWithdraw?.money_token
    ) {
      toast.error("Balance Not Enough");
      return;
    }
    if (
      id === "transfer" &&
      wallet?.key === "TON_Internal" &&
      Number(amount) > balanceWithdraw?.money_token_internal
    ) {
      toast.error("Balance Not Enough");
      return;
    }
    if (
      id === "transfer" &&
      wallet?.key === "iTON" &&
      Number(amount) > balanceWithdraw?.money_iton
    ) {
      toast.error("Balance Not Enough");
      return;
    }
    if(id === "transfer"){
      if (Number(amount) * TokenInfo?.prices?.USD < 20) {
        toast.error(
          `Transfer a minimum of $20`
        );
        return;
      }
    }
    if(id === "transfer"){
      if (Number(amount) * TokenInfo?.prices?.USD < 20) {
        toast.error(
          `Transfer a minimum of $20`
        );
        return;
      }
    }else if(!id){
      if (Number(amount) * TokenInfo?.prices?.USD < 50) {
        toast.error(
          `Withdraw a minimum of $50`
        );
        return;
      }
    }
    if (!revierAddress && id === "transfer") {
      toast.error("Received wallet is invalid");
      return;
    }
    setLoadingSub(true);

    const handleConfirm = async () => {
      let result: any;
      if (id === "withdraw" || !id) {
        result = await dispatch(
          // @ts-ignore
          WidthrawSubmit({
            amount: Number(amount),
            type: "TON",
          })
        );
        hadleGetValByDay();
      } else if (id === "transfer") {
        if(wallet?.key === "iTON"){
          result = await dispatch(
            // @ts-ignore
            TranferITONSubmit({
              to_address: revierAddress,
              amount: amount
            })
          );
        }else{
          result = await dispatch(
            // @ts-ignore
            TranferSubmit({
              to_address: revierAddress,
              amount: amount,
              from_mainnet: wallet?.key === "TON_Mainnet",
            })
          );
        }
      }
      if (id === "withdraw" || !id) {
        if (result.payload.status === 200) {
          dispatch(getDasboardInfo());
          dispatch(getTokenInfo());
          dispatch(getWidthrawList());
          dispatch(getBalance());
          dispatch(getFee());
          toast.success("Successfully");
        } else if (
          result.payload?.error?.data?.errorKey === `user.balanceNotEnough`
        ) {
          toast.error("Balance Not Enough");
        } else if (
          result.payload?.error?.data?.errorKey === `withdrawalRequest.pending`
        ) {
          toast.error("There is another transaction pending confirmation");
        } else {
          toast.error("There is an error. Please try again.");
        }
      } else {
        if (result.payload.data) {
          if (result.payload.data?.is_sucess) {
            dispatch(getDasboardInfo());
            dispatch(getTokenInfo());
            dispatch(getWidthrawList());
            dispatch(getBalance());
            dispatch(getFee());
            dispatch(getDEPOSITList());
            dispatch(getCONVERTList());
            dispatch(getTRANSFERList());
            toast.success("Successfully");
          } else {
            toast.error(result.payload.data.message);
          }
        } else {
          toast.error("There is an error. Please try again.");
        }
      }
      setAmount(0);
      setRevierAddress("");
      setLoadingSub(false);
    };
    handleOpenCustom(
      <ModalConfirm
        handleConfirm={handleConfirm}
        tabsActive={id === "transfer" ? 2 : 1}
        amount={amount}
        address={revierAddress}
      />
    );
  };
  const [showList, setShowList] = useState(false);
  useEffect(() => {
    setAmount(0);
    setShowList(false);
  }, [id]);

  const handleInvestment = async () => {
    if (Number(amount) > 0) {
      let amountSubmit = `${toNano(Number(amount))}`;
      console.log("amountSubmit", amountSubmit);

      setLoadingSub(true);

      const handleTx = async () => {
        try {
          const ResWallet = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}wallets/address-deposit`,
            {
              headers: {
                Authorization: localStorage.getItem("token") || null,
              },
            }
          );

          const Res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}deposit`,
            {
              amount: amount,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token") || null,
              },
            }
          );
          if (Res.status !== 200 || ResWallet.status !== 200) {
            toast.error("There is a slight issue. Please try again.");
            setLoadingSub(false);
          }
          dispatch(getDEPOSITList());
          const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 360,
            messages: [
              {
                address: `${ResWallet.data.address_deposit}`,
                amount: `${toNano(amount)}`,
                payload: `${Res.data.payload}`.toString(),
              },
            ],
          };
          let res: any = { data: "test" };
          res = await tonConnectUI.sendTransaction(transaction);

          if (res.boc) {
            toast.success(
              "Deposit transaction successful. Please wait a few minutes"
            );
            dispatch(getDasboardInfo());
            dispatch(getTokenInfo());
            dispatch(getWidthrawList());
            dispatch(getBalance());
            dispatch(getFee());
            dispatch(getCONVERTList());
            dispatch(getTRANSFERList());
            setLoadingSub(false);
          }
        } catch (e: any) {
          if (e.response.data.message === "deposit.pending") {
            toast.error("There is another transaction pending confirmation");
          }
          setAmount(0);
          setLoadingSub(false);
        }
      };
      handleOpenCustom(
        <ModalConfirm
          handleConfirm={handleTx}
          tabsActive={3}
          amount={Number(amount)}
          setLoadingSub={setLoadingSub}
          // address={revierAddress}
        />
      );
      // timeOut.current = setTimeout(() => {
      // setLoadingSub(false);
      // }, 3000);
    } else {
      toast.error("invalid amount");
    }
  };
  const HandleConver = async () => {
    if (amount > 0) {
      const ConfimrAction = async () => {
        const Res = await instance.post("/wallets/convert-balance", {
          amount_ton: Number(amount),
        });
        if (Res.status === 200) {
          toast.success("Successfully");
          dispatch(getDasboardInfo());
          dispatch(getTokenInfo());
          dispatch(getWidthrawList());
          dispatch(getBalance());
          dispatch(getFee());
        }
      };
      handleOpenCustom(
        <ModalConfirm
          handleConfirm={ConfimrAction}
          tabsActive={-2}
          amount={Number(amount)}
          setLoadingSub={setLoadingSub}
          // address={revierAddress}
        />
      );
    }
  };
  return (
    <>
      <WithdrawWapper>
        <BoxWallet>
          <WithdrawBalance>
            <WithdrawBalanceText>
              <h6>
                TON Mainnet{" "}
                <Dropdown
                  menu={{
                    items: TonOnChain,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <InfoCircleOutlined
                        style={{ fontSize: 18, color: "#ffffff90" }}
                      />
                    </Space>
                  </a>
                </Dropdown>{" "}
              </h6>
              <h3>
                {balanceWithdraw?.money_token
                  ? formatNumber(
                      (
                        Math.floor(Number(balanceWithdraw?.money_token) * 100) /
                        100
                      )?.toFixed(2)
                    )
                  : 0}{" "}
                <img
                  src="https://app.tonholding.io/assets/ton_symbol.svg"
                  alt="icon"
                />
              </h3>
              <p>
                ~
                {TokenInfo?.prices?.USD && balanceWithdraw?.money_token
                  ? formatNumber(
                      (
                        Number(balanceWithdraw?.money_token) *
                        TokenInfo?.prices?.USD
                      )?.toFixed(2)
                    )
                  : 0}{" "}
              </p>
            </WithdrawBalanceText>
          </WithdrawBalance>
          <WithdrawBalance>
            <WithdrawBalanceText>
              <h6>
                TON Internal{" "}
                <Dropdown
                  menu={{
                    items: ton_internal,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <InfoCircleOutlined
                        style={{ fontSize: 18, color: "#ffffff90" }}
                      />
                    </Space>
                  </a>
                </Dropdown>{" "}
              </h6>
              <h3>
                {" "}
                {balanceWithdraw?.money_token_internal
                  ? formatNumber(
                      (
                        Math.floor(
                          Number(balanceWithdraw?.money_token_internal) * 100
                        ) / 100
                      )?.toFixed(2)
                    )
                  : 0}{" "}
                <img
                  src="https://app.tonholding.io/assets/ton_symbol.svg"
                  alt="icon"
                />
              </h3>
              <p>
                ~
                {TokenInfo?.prices?.USD && balanceWithdraw?.money_token_internal
                  ? formatNumber(
                      (
                        Number(balanceWithdraw?.money_token_internal) *
                        TokenInfo?.prices?.USD
                      )?.toFixed(2)
                    )
                  : 0}{" "}
              </p>
            </WithdrawBalanceText>
          </WithdrawBalance>
          <WithdrawBalance>
            <WithdrawBalanceText>
              <h6>
                iTON{" "}
                <Dropdown
                  menu={{
                    items: iTonOnChain,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <InfoCircleOutlined
                        style={{ fontSize: 18, color: "#ffffff90" }}
                      />
                    </Space>
                  </a>
                </Dropdown>
              </h6>
              <span className="Text_price">Price: ${iTonPrice || " -- "}</span>
              <h3>
                {" "}
                {balanceWithdraw?.money_iton
                  ? formatNumber(
                      (
                        Math.floor(Number(balanceWithdraw?.money_iton) * 100) /
                        100
                      )?.toFixed(2)
                    )
                  : 0}{" "}
                <img
                  src="https://app.tonholding.io/assets/iton_symbol.svg"
                  alt="icon"
                />
              </h3>
              <p>
                ~
                {iTonPrice && balanceWithdraw?.money_iton
                  ? formatNumber(
                      (
                        Number(balanceWithdraw?.money_iton) * iTonPrice
                      )?.toFixed(2)
                    )
                  : 0}{" "}
              </p>
            </WithdrawBalanceText>
          </WithdrawBalance>
          <WithdrawBalance>
            <WithdrawBalanceText>
              <h6>
                iTON IDO{" "}
                <Dropdown
                  menu={{
                    items: iTonReward,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <InfoCircleOutlined
                        style={{ fontSize: 18, color: "#ffffff90" }}
                      />
                    </Space>
                  </a>
                </Dropdown>{" "}
              </h6>
              <span className="Text_price">Price: ${iTonPrice || "--"}</span>
              <h3>
                {" "}
                {balanceWithdraw?.money_iton_reward
                  ? formatNumber(
                      (
                        Math.floor(
                          Number(balanceWithdraw?.money_iton_reward) * 100
                        ) / 100
                      )?.toFixed(2)
                    )
                  : 0}{" "}
                <img
                  src="https://app.tonholding.io/assets/iton_symbol.svg"
                  alt="icon"
                />
              </h3>
              <p>
                ~
                {iTonPrice && balanceWithdraw?.money_iton_reward
                  ? formatNumber(
                      (
                        Number(balanceWithdraw?.money_iton_reward) * iTonPrice
                      )?.toFixed(2)
                    )
                  : 0}
              </p>
            </WithdrawBalanceText>
          </WithdrawBalance>
        </BoxWallet>
        <WithdrawConten>
          <WithdrawInputGroup>
            <WithdrawInputBox>
              <WithdrawTabs>
                <WithdrawTab
                  className={
                    tabsActive === 1
                      ? "Tab_Active"
                      : id === "transfer"
                      ? ""
                      : ""
                  }
                  show={showList}
                  onClick={() => {
                    setShowList(!showList);
                  }}
                >
                  {/* <input
                    onFocus={() => {
                      setShowList(true);
                    }}
                    onBlur={(e:any) => {
                      e.preventDefault();
                      setShowList(false);
                    }}
                  /> */}
                  <BoxTitleRadius>
                    <h3>
                      {id === "withdraw"
                        ? "Withdraw"
                        : id === "transfer"
                        ? "Transfer"
                        : id === "convert"
                        ? "Convert"
                        : id === "deposit"
                        ? "Deposit"
                        : "Withdraw"}
                    </h3>
                    <img
                      src="https://app.tonholding.io/assets/arrowBlue.svg"
                      style={{
                        transform: `rotateZ(${showList ? 180 : 0}deg)`,
                        width: "15px",
                        marginRight: "15px",
                        filter: "brightness(5)",
                      }}
                    />
                  </BoxTitleRadius>
                  <ul >
                    <li>
                      <Link to="/Wallet/withdraw">Withdraw</Link>
                    </li>
                    <li>
                      <Link to="/Wallet/transfer">Transfer</Link>
                    </li>
                    <li>
                      <Link to="/Wallet/deposit">Deposit</Link>
                    </li>
                    <li>
                      <Link to="/Wallet/convert">Convert</Link>
                    </li>
                  </ul>
                </WithdrawTab>
                {/* <WithdrawTab
                  className={tabsActive !== 1 ? "Tab_Active" : ""}
                  onClick={() => {
                    setTabsActive(2);
                  }}
                >
                  <BoxTitleRadius>
                    <h3>Transfer</h3>
                  </BoxTitleRadius>
                </WithdrawTab> */}
              </WithdrawTabs>
              {id === "withdraw" ? (
                <WidthRawBox
                  amount={amount}
                  setAmount={setAmount}
                  fee={fee}
                  setFee={setFee}
                  handleWithdraw={handleWithdraw}
                  loadingSub={loadingSub}
                  hadleGetValByDay={hadleGetValByDay}
                  maxByDay={maxByDay}
                />
              ) : id === "transfer" ? (
                <TransferBox
                  amount={amount}
                  setAmount={setAmount}
                  fee={0}
                  setFee={setFee}
                  handleWithdraw={handleWithdraw}
                  loadingSub={loadingSub}
                  setRevierAddress={setRevierAddress}
                  revierAddress={revierAddress}
                  iTonPrice={iTonPrice}
                ></TransferBox>
              ) : id === "convert" ? (
                <ConverBox
                  amount={amount}
                  setAmount={setAmount}
                  fee={0}
                  setFee={setFee}
                  handleWithdraw={HandleConver}
                  loadingSub={loadingSub}
                  setRevierAddress={setRevierAddress}
                  revierAddress={revierAddress}
                ></ConverBox>
              ) : id === "deposit" ? (
                <DepositBox
                  amount={amount}
                  setAmount={setAmount}
                  // fee={fee}
                  // setFee={setFee}
                  handleDeposit={handleInvestment}
                  loadingSub={loadingSub}
                ></DepositBox>
              ) : (
                <WidthRawBox
                  amount={amount}
                  setAmount={setAmount}
                  fee={fee}
                  setFee={setFee}
                  handleWithdraw={handleWithdraw}
                  loadingSub={loadingSub}
                  hadleGetValByDay={hadleGetValByDay}
                  maxByDay={maxByDay}
                />
              )}
            </WithdrawInputBox>
            {isMobile && (
              <TableWithdrawMobile>
                <BoxTitleRadius>
                  <h3>History</h3>
                </BoxTitleRadius>
                <TableWithdraw
                  WidthrawList={
                    id === "withdraw"
                      ? WidthrawList
                      : id === "transfer"
                      ? TRANSFERList
                      : id === "convert"
                      ? CONVERTList
                      : id === "deposit"
                      ? DEPOSITList
                      : WidthrawList
                  }
                />
              </TableWithdrawMobile>
            )}
          </WithdrawInputGroup>
          <WithdrawHisBox>
            {!isMobile && (
              <>
                <BoxTitleRadius>
                  <h3>History</h3>
                </BoxTitleRadius>
                <TableWithdraw
                  WidthrawList={
                    id === "withdraw"
                      ? WidthrawList
                      : id === "transfer"
                      ? TRANSFERList
                      : id === "convert"
                      ? CONVERTList
                      : id === "deposit"
                      ? DEPOSITList
                      : WidthrawList
                  }
                />
              </>
            )}
          </WithdrawHisBox>
        </WithdrawConten>
      </WithdrawWapper>
    </>
  );
};

export default Withdraw;
