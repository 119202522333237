import { useSelector } from "react-redux";
import { BoxNotification, Submit } from "../../Staking/styled";
import { BoxSelectWallet, WithdrawInput, WithdrawInputAmount } from "../style";
import { formatNumber } from "../../../utils/formatNumber";
import NotiIcon from "../../../assets/Staking/CompoundBadge.svg";
import { useState } from "react";
import styled from "styled-components";

const TransferBox = ({
  amount,
  setAmount,
  fee,
  setFee,
  handleWithdraw,
  loadingSub,
  setRevierAddress,
  revierAddress,
  iTonPrice,
}: any) => {
  const balanceWithdraw = useSelector(
    (state: any) => state.withdraw.balanceWithdraw
  );
  const TokenInfo = useSelector((state: any) => state.token.TokenInfo);
  const Fee = useSelector((state: any) => state.withdraw.settingList);
  const [activeWallet, setActiveWallet] = useState(ListWallet[0]);
  const [iShow, setIsShow] = useState(false);
  console.log("balanceWithdraw", balanceWithdraw);

  return (
    <TranferBox>
      <WithdrawInput>
        <TabList>
          {ListWallet.map((wallet: any) => (
            <Tabs
              onClick={() => {
                setActiveWallet(wallet);
              }}
              className={wallet.key === activeWallet.key ? "active" : ""}
            >
              <p>{wallet.title}</p>
            </Tabs>
          ))}
        </TabList>
        <WithdrawInputAmount >
          <h3>
            Amount
            {/* <BoxSelectWallet
            onClick={() => {
              setIsShow(!iShow);
            }}
          >
            <div>
              <p>{activeWallet.title}</p>
              <img src="https://app.tonholding.io/assets/arrowBlue.svg" />
              <ul
                style={{
                  opacity: iShow ? 1 : 0,
                  pointerEvents: iShow ? "auto" : "none",
                  width: "fit-content",
                  minWidth: "100%",
                  right: 0,
                }}
              >
                {ListWallet.map((wallet: any) =>
                  wallet.key !== activeWallet.key ? (
                    <li
                      onClick={() => {
                        setActiveWallet(wallet);
                      }}
                    >
                      {wallet.title}
                    </li>
                  ) : (
                    <></>
                  )
                )}
              </ul>
            </div>
          </BoxSelectWallet>{" "} */}
          </h3>
          <div>
            <input
              type="number"
              pattern="0.00"
              value={amount ? amount : ""}
              onChange={(e: any) => {
                e.target.value >= 0 && setAmount(e.target.value);
              }}
            />
            <button
              onClick={() => {
                activeWallet.key === "TON_Mainnet"
                  ? setAmount(
                      Math.floor(Number(balanceWithdraw?.money_token)) || 0
                    )
                  : activeWallet.key === "TON_Internal" ? setAmount(
                      Math.floor(
                        Number(balanceWithdraw?.money_token_internal)
                      ) || 0
                    ) : setAmount(
                      Math.floor(
                        Number(balanceWithdraw?.money_iton)
                      ) || 0
                    );
              }}
            >
              Max
            </button>
          </div>
          <p>
            ~$
            {activeWallet.key !== "iTON"
              ? TokenInfo?.prices?.USD
                ? formatNumber(
                    (Number(amount) * TokenInfo?.prices?.USD)?.toFixed(2)
                  )
                : 0
              : iTonPrice
              ? formatNumber((Number(amount) * Number(iTonPrice))?.toFixed(2))
              : 0}{" "}
          </p>
          <h3>Wallet Address:</h3>
          <div style={{ marginBottom: 8 }}>
            <input
              type="text"
              pattern="0.00"
              value={revierAddress}
              onChange={(e: any) => {
                setRevierAddress(e.target.value);
              }}
            />
          </div>
          <BoxNotification style={{marginBottom:10}} >
            <div>
              <img src={NotiIcon} alt="icon" />
              <p>Fee Transfer</p>
            </div>
            <h6>
              <span>{fee}%</span>
              {`(~$${
                fee
                  ? `${
                      TokenInfo?.prices?.USD
                        ? formatNumber(
                            (
                              (Number(amount) * TokenInfo?.prices?.USD * fee) /
                              100
                            )?.toFixed(2)
                          )
                        : 0
                    }`
                  : "0"
              })`}
            </h6>
          </BoxNotification>
          <h3>Minimum Transfer $20</h3>
          <Submit
            onClick={() => {
              !loadingSub && handleWithdraw(activeWallet);
            }}
            style={{
              opacity:
                amount && Number(amount) * TokenInfo?.prices?.USD >= 20
                  ? activeWallet.key === "TON_Mainnet" &&
                    Number(amount) <= balanceWithdraw?.money_token
                    ? "1"
                    : activeWallet.key === "TON_Internal" &&
                      Number(amount) <= balanceWithdraw?.money_token_internal
                    ? "1"
                    : activeWallet.key === "iTON" &&
                      Number(amount) <= balanceWithdraw?.money_iton &&
                      iTonPrice &&
                      Number(amount) * iTonPrice >= 20
                    ? 1
                    : "0.5"
                  : "0.5",
            }}
          >
            {loadingSub ? "Loading..." : "Transfer"}
          </Submit>
        </WithdrawInputAmount>
      </WithdrawInput>
    </TranferBox>
  );
};
export default TransferBox;

const ListWallet = [
  {
    title: "TON Mainnet",
    key: "TON_Mainnet",
  },
  {
    title: "TON Internal",
    key: "TON_Internal",
  },
  {
    title: "iTON",
    key: "iTON",
  },
];

const TabList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 16px 16px 16px 16px; */
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  background: var(--Blue-100, #001b33);
  .active {
    background: #00427f;
    p {
      color: var(--Gray-10, #e7e8ec);
    }
  }
`;

const Tabs = styled.div`
  padding: 16px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
  cursor: pointer;
  transition: all 0.3s linear;
  p {
    color: var(--Gray-10, #e7e8ec50);
    leading-trim: both;
    text-edge: cap;
    font-family: "Anek Odia";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
  }
  @media only screen and (max-width: 1024px) {
    p {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 768px) {
    p {
      font-size: 16px;
    }
  }
`;

const TranferBox = styled.div`
  ${WithdrawInput} {
    padding: 0px;
  }
  ${WithdrawInputAmount}{
    padding: 0px 16px 16px 16px;
  }
  @media only screen and (max-width: 768px) {
    ${WithdrawInput} {
      padding: 0px 12px 12px;
      gap: 0px;
    }
    ${WithdrawInputAmount}{
      border-radius: 0px 0px 16px 16px;
      padding-top: 12px;
    }
  }
`;
