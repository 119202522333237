import React, { useCallback, useEffect, useRef, useState } from "react";
import Tree from "react-d3-tree";
import styled from "styled-components";
import {
  NodeAvatar,
  NodeBtn,
  NodeTreeCTN,
  NodeTreeContent,
  NodeTreeElm,
  NodeTreeTitle,
  TextTollBox,
  Toll,
} from "../styled";
import star from "../../../assets/Affilliate/Star.svg";
import { RootState, useDispatch, useSelector } from "../../../store/reDucStore";
import { getNetwordTree } from "../../../store/Slices/NetWord";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  Handle,
  Position,
  Controls,
} from "reactflow";
// @ts-ignore
import dagre from "dagre";
import "reactflow/dist/style.css";
import moment from "moment";
import { formatNumber } from "../../../utils/formatNumber";
const TreeNodeWapper = styled.div`
  width: 100%;
  background: var(--Gray-100, #1b1c20);
  width: 100%;
  border-radius: 16px;
  flex: 1;
  .linkBase {
    stroke: rgba(141, 142, 144, 1);
  }
  foreignObject {
    overflow: auto !important;
  }
  .react-flow__node {
    &:hover {
      z-index: 1 !important;
      ${Toll} {
        transition: opacity 0.3s linear;
        opacity: 1;
        width: 332px;
        display: flex;
      }
    }
  }
`;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 250;
const nodeHeight = 131;

const getLayoutedElements = (nodes: any, edges: any, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
    

    return node;
  });

  return { nodes, edges };
};

const TreeNode = ({ nodeData, Edges }: any) => {
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    nodeData,
    Edges
  );
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const nodeTypes = { nodeElm: TextUpdaterNode };
  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );
  const onLayout = useCallback(
    (direction: any) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        console.log("node", node);
        const newNode = { ...node, data: { ...node } };
        return newNode;
      })
    );
  }, [nodeData, setNodes]);
  console.log("nodes", nodes);

  return (
    <TreeNodeWapper>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        // onNodesChange={onNodesChange}
        // onEdgesChange={onEdgesChange}
        // onLayout={onLayout}
        onConnect={onConnect}
        connectionLineType={ConnectionLineType.SmoothStep}
        nodeTypes={nodeTypes}
        minZoom={0.001}
        fitView
      >
        <Controls
          showFitView={true}
          showZoom={true}
          fitViewOptions={{ minZoom: 0.0001 }}
        />
      </ReactFlow>
    </TreeNodeWapper>
  );
};
export default TreeNode;

function TextUpdaterNode({ data, isConnectable }: any) {
  return (
    <>
      <div className={`text-updater-node${data?.user_id}`}>
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
        />
        <NodeTreeCTN>
          <NodeTreeElm>
            <NodeTreeTitle>
              <NodeAvatar>
                <img src="./assets/LogoTon.svg" />
              </NodeAvatar>
              {data?.ranking_info || data?.ranking ? (
                <NodeBtn bg={!data?.invest_token && "#5a5f6b"}>
                  <div>
                    <img src={star} />
                  </div>{" "}
                  <h6>
                    {data?.ranking_info || data?.ranking === "Member"
                      ? "Nominator"
                      : data?.ranking_info || data?.ranking || "--"}
                  </h6>
                </NodeBtn>
              ) : (
                <></>
              )}
            </NodeTreeTitle>
            <NodeTreeContent>
              <h3>
                Address :{" "}
                {`${data?.address?.slice(0, 4)}...${data?.address?.slice(-4)}`}
              </h3>
              <p style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                Invest :
                {"  ~$"}
                {formatNumber(data?.invest_usd?.toFixed(2)) || 0}
              </p>
            </NodeTreeContent>
          </NodeTreeElm>
        </NodeTreeCTN>
        <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          // style={handleStyle}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
        />
      </div>
      <Toll>
        <TextTollBox>
          <p>UserName:</p>
          <p>{`${data?.user_id?.slice(0, 3)}...${data?.user_id?.slice(-3)}`}</p>
        </TextTollBox>
        <TextTollBox>
          <p>Address:</p>
          <p>{`${data?.address}`}</p>
        </TextTollBox>
        <TextTollBox>
          <p>Ranking:</p>
          <p>{`${
            data?.ranking_info || data?.ranking === "Member"
              ? "Nominator"
              : data?.ranking_info || data?.ranking || "--"
          }`}</p>
        </TextTollBox>
        <TextTollBox>
          <p>Left revenue :</p>
          <p>{`${formatNumber(data?.left_branch_revenue?.toFixed(2)) || 0}`}</p>
        </TextTollBox>
        <TextTollBox>
          <p>Right revenue :</p>
          <p>{`${
            formatNumber(data?.right_branch_revenue?.toFixed(2)) || 0
          }`}</p>
        </TextTollBox>
        <TextTollBox>
        </TextTollBox>
        <TextTollBox>
          <p>Invest (USD) :</p>
          <p>
            {"  ~$"}
            {formatNumber(data?.invest_usd?.toFixed(2)) || 0}
          </p>
        </TextTollBox>
        <TextTollBox>
          <p>Create date:</p>
          <p>{`${
            data?.created_at && moment(data?.created_at).format("DD/MM/YYYY")
          }`}</p>
        </TextTollBox>
      </Toll>
    </>
  );
}
